<template>
  <div class="course-edit">
    <!--v-loading="pageLoading"-->
    <el-card class="box-card publishDetail"
             shadow="never">
      <div class="course-header content"
           :style="{ 'background-image': 'url('+defaultHeaderBg+')', 'background-repeat': 'no-repeat' , 'background-size': '100% 6.25vw' }">
        <div class="header-left">
          <div class="img-div">
            <img :src="courseContentInfo.coverImg ? courseContentInfo.coverImg : defaultHeaderImg"
                 class="course-img" />
          </div>
          <div class="course-info">
            <div class="title">
              <span>{{ courseContentInfo.courseThemeName }}</span>
            </div>
            <!-- <div class="description">{{ courseContentInfo.description }}</div> -->
            <el-tooltip class="item"
                        effect="light"
                        popper-class="customPopper"
                        :content="courseContentInfo.description"
                        placement="top"
                        v-if="courseContentInfo.description">
              <div class="description"
                   v-if="courseContentInfo.description">{{ courseContentInfo.description }}</div>
            </el-tooltip>
            <div class="description"
                 v-if="!courseContentInfo.description"></div>
            <div class="course-info-bottom"
                 v-if="courseContentInfo.userName && courseContentInfo.createTime">
              <div class="bottom-item">
                <div class="label">老师：</div>
                <div class="text">{{ courseContentInfo.userName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item">
                <div class="label long">创建时间：</div>
                <div class="text long">{{ courseContentInfo.createTime }}</div>
              </div>
            </div>
            <div class="course-info-bottom"
                 v-else></div>
          </div>
        </div>
      </div>
    </el-card>
    <div class="content-body">
      <div class="body-left"
           ref="bodyLeft"
           :style="{ height: bodyLeftHeight }"
           v-if="$route.query.id">
        <div class="tree-list"
             v-if="catalogData.length > 0">
          <div class="tree-outside"
               v-for="(treeInfo,j) in catalogData"
               :key="treeInfo.id">
            <div class="tree-item">
              <div class="tree-title"
                   :ref="'tree-title-'+treeInfo.id"
                   :class="{ parentShow: treeInfo.isOpen === '1' ,active: treeInfo.id == courseContentCatalogId }"
                   @click="handleTreeClick(treeInfo,treeInfo,j,null,null)">
                <div>{{ treeInfo.catalogueName }}</div>
                <div v-if="treeInfo.isOpen === '1'"
                     class="parent-show">家长</div>
                <i :class="{ 'el-icon-caret-top': treeInfo.isShowTree, 'el-icon-caret-bottom': !treeInfo.isShowTree }"
                   v-if="treeInfo.children"
                   @click.stop="handleTreeTurn(treeInfo,j,null,null)"></i>
              </div>
              <div v-if="treeInfo.children && treeInfo.isShowTree"
                   class="sub-tree-list">
                <div class="tree-item"
                     v-for="(subTreeInfo,k) in treeInfo.children"
                     :key="subTreeInfo.id">
                  <div class="tree-title"
                       :style="{ 'padding-left': '2.60417vw', 'width': subTreeInfo.id == courseContentCatalogId ? ( subTreeInfo.isOpen === '1' ? 'calc(100% - 6.04167vw)' : 'calc(100% - 3.75vw)' ) : ( subTreeInfo.isOpen === '1' ? 'calc(100% - 5.83333vw)' : 'calc(100% - 3.54167vw)' )}"
                       :ref="'tree-title-'+subTreeInfo.id"
                       :class="{ parentShow: subTreeInfo.isOpen === '1' ,active: subTreeInfo.id == courseContentCatalogId }"
                       @click.stop="handleTreeClick(subTreeInfo,treeInfo,j,subTreeInfo,k)">
                    <div>{{ subTreeInfo.catalogueName }}</div>
                    <div v-if="subTreeInfo.isOpen === '1'"
                         class="parent-show">家长</div>
                    <i :class="{ 'el-icon-caret-top': subTreeInfo.isShowTree, 'el-icon-caret-bottom': !subTreeInfo.isShowTree }"
                       style="left: 1.5625vw;"
                       v-if="subTreeInfo.children"
                       @click.stop="handleTreeTurn(treeInfo,j,subTreeInfo,k)"></i>
                  </div>
                  <div v-if="subTreeInfo.children && subTreeInfo.isShowTree"
                       class="sub-tree-list">
                    <div class="tree-item"
                         v-for="thirdTreeInfo in subTreeInfo.children"
                         :key="thirdTreeInfo.id">
                      <div class="tree-title"
                           :style="{ 'padding-left': '3.125vw' ,width: thirdTreeInfo.id == courseContentCatalogId ? ( thirdTreeInfo.isOpen === '1' ? 'calc(100% - 6.5625vw)' : 'calc(100% - 4.27083vw)' ) : ( thirdTreeInfo.isOpen === '1' ? 'calc(100% - 6.35417vw)' : 'calc(100% - 4.0625vw)' ) }"
                           :ref="'tree-title-'+thirdTreeInfo.id"
                           :class="{ parentShow: thirdTreeInfo.isOpen === '1' ,active: thirdTreeInfo.id == courseContentCatalogId, }"
                           @click.stop="handleTreeClick(thirdTreeInfo)">
                        <div>{{ thirdTreeInfo.catalogueName }}</div>
                        <div v-if="thirdTreeInfo.isOpen === '1'"
                             class="parent-show">家长</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body-right"
           ref="bodyRight"
           :style="{ height: bodyRightHeight, width: $route.query.id ? 'calc(100% - 15vw)' : '100%' }">
        <div class="content-title">{{ contentTitle }}</div>
        <div class="first-panel"
             v-if="courseContentTargetInfo.length > 0">
          <div class="panel-header">
            <div class="title">
              <div class="title-icon"></div>目标清单
            </div>
            <div class="button"></div>
          </div>
          <div class="panel-content">
            <el-table class="tableDialog"
                      :data="courseContentTargetInfo"
                      style="width: 100%"
                      ref="dialog-table"
                      v-loading="tableLoading">
              <el-table-column prop="descriptionCode"
                               label="指标编号"
                               width="200"
                               align="center"></el-table-column>
              <el-table-column prop="descriptionCode"
                               label="指标库"
                               width="250"
                               align="center"></el-table-column>
              <el-table-column prop="catalogueName"
                               label="目标名称"
                               align="center"
                               width="300"></el-table-column>
              <el-table-column prop="descriptionContent"
                               label="指标内容"
                               align="center"
                               width="300"></el-table-column>
              <el-table-column prop="targetSuggest"
                               label="教学建议"
                               width="120"
                               align="center"
                               fixed="right">
                <template slot-scope="scope">
                  <el-button type="text"
                             v-if="scope.row.sortIndex != 0"
                             class="button"
                             @click="handleTaggetItem('suggestView', scope.row)">查看</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="targetPractice"
                               label="教学实践"
                               width="120"
                               align="center"
                               fixed="right">
                <template slot-scope="scope">
                  <el-button type="text"
                             v-if="scope.row.sortIndex != 0"
                             class="button"
                             @click="handleTaggetItem('practiceView', scope.row)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="panel-data"
             v-if="courseContentList.length > 0">
          <div class="first-panel"
               v-for="(item, index) in courseContentList"
               :key="item.id">
            <div class="panel-header"
                 v-if="$route.query.id || (!$route.query.id && item.eduCourseThemeCatalogueContentTrees && item.eduCourseThemeCatalogueContentTrees.length > 0 && item.eduCourseThemeCatalogueContentTrees[0].contentType != '2') || (!$route.query.id && item.contentType != '2')">
              <div class="title">
                <div class="title-icon"></div>{{ item.contentName }}
              </div>
              <div class="button">
                <img :src="firstPanelTurnIcon"
                     alt=""
                     @click="handleContentItem('turnItem', null, null, item, index)"
                     style="margin-left: 0.41667vw"
                     :class="{ 'turn-icon-class': !item.showFlag }" />
              </div>
            </div>
            <div class="panel-content"
                 v-show="item.showFlag"
                 v-if="item.eduCourseThemeCatalogueContentTrees.length > 0 && item.eduCourseThemeCatalogueContentTrees[0].contentType != '2'">
              <div class="second-panel"
                   v-for="(info, i) in item.eduCourseThemeCatalogueContentTrees"
                   :key="info.id">
                <div class="panel-header">
                  <div class="title">{{ info.contentName }}</div>
                  <div class="button">
                    <img :src="secondPanelTurnIcon"
                         alt=""
                         @click="handleContentItem('turnItem', info, i, item, index)"
                         style="margin-left: 0.41667vw"
                         :class="{ 'turn-icon-class': !info.showFlag }" />
                  </div>
                </div>
                <div class="panel-content"
                     v-show="info.showFlag"
                     v-if="info.contentType == '0'">
                  <div class="content-info"
                       v-if="info.eduCourseThemeContent.length > 0">
                    <!-- <div class="ql-container ql-snow">
                      <div class="ql-editor" v-html="info.eduCourseThemeContent[0].content"></div>
                    </div> -->
                    <div class="w-e-text-container"
                         @click="handleImgClick">
                      <div class="w-e-text"
                           v-html="info.eduCourseThemeContent[0].content"></div>
                    </div>
                  </div>
                  <div class="file-info"
                       v-if="info.eduCourseThemeCatalogueContentFile && info.eduCourseThemeCatalogueContentFile.length > 0">
                    <div class="file-item"
                         v-for="(fileInfo, fileIndex) in info.eduCourseThemeCatalogueContentFile"
                         :key="fileInfo.id"
                         :style="{ 'margin-right': ((fileIndex+1) % fileNum == 0) ? '0' : '0.52083vw', width: '22.08333vw' }">
                      <div class="content">
                        <img class="icon"
                             :src="fileIconPDF"
                             alt=""
                             v-if="fileInfo.iconType == 'pdf'" />
                        <img class="icon"
                             :src="fileIconWord"
                             alt=""
                             v-if="fileInfo.iconType == 'doc'" />
                        <img class="icon"
                             :src="fileIconExcel"
                             alt=""
                             v-if="fileInfo.iconType == 'xls'" />
                        <img class="icon"
                             :src="fileIconPPT"
                             alt=""
                             v-if="fileInfo.iconType == 'ppt'" />
                        <div class="message">
                          <div class="title">{{ fileInfo.originalName }}</div>
                          <!-- <el-tooltip class="item" effect="light" popper-class="customPopper" :content="fileInfo.remark" placement="top-start">
                            <div class="ltitle">{{ fileInfo.remark }}</div>
                          </el-tooltip> -->
                        </div>
                      </div>
                      <div class="bottom">
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    content="附件下载"
                                    placement="bottom"
                                    v-if="fileInfo.isDownload == '1'">
                          <img :src="fileDownloadIcon"
                               alt=""
                               @click="fileView(fileInfo,'download')"
                               style="margin-right: 0.41667vw"
                               v-if="fileInfo.isDownload == '1'" />
                        </el-tooltip>
                        <el-divider direction="vertical"
                                    v-if="fileInfo.isDownload == '1'"></el-divider>
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    content="附件预览"
                                    placement="bottom">
                          <img :src="fileViewIcon"
                               alt=""
                               @click="fileView(fileInfo,'view')"
                               style="margin: 0 0.26042vw 0 0.41667vw" />
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="panel-content"
                     v-show="info.showFlag"
                     v-if="info.contentType == '1'">
                  <el-row style="padding: 1.25vw 0.9375vw;">
                    <!--type="flex"-->
                    <!-- <el-col :span="videoSpan" v-for="(video, k) in info.eduCourseThemeCatalogueContentVideo" :key="video.id" :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '3.5vw' : '0', 'margin-bottom': '1.04167vw', }"> 'margin-right': (index + 1) % 4 != 0 ? '5.4%' : '0', -->
                    <el-card shadow="hover"
                             class="video-card"
                             v-for="(video, k) in info.eduCourseThemeCatalogueContentVideo"
                             :key="video.id"
                             :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '0.67708vw' : '0', 'margin-bottom': '0.67708vw', 'width': '22.08333vw', display: 'inline-block' }"
                             :body-style="{ padding: 'undefined' }"
                             @click.native="videoView(video)">
                      <div class="video-body"
                           :style="{ 'background-image': 'url(' + (video.fontImg ? video.fontImg : defaultImg) + ')', 'background-size': '100% 100%', }">
                        <img :src="videoPlayIcon"
                             alt="" />
                      </div>
                      <div class="info-body video-item">
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    :content="video.videoName"
                                    placement="top">
                          <div class="title">{{ video.videoName }}</div>
                        </el-tooltip>
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    :content="video.intro"
                                    placement="top"
                                    v-if="video.intro">
                          <div class="ltitle"
                               v-if="video.intro">{{ video.intro }}</div>
                        </el-tooltip>
                        <div class="bottom">
                          <div class="video-time">{{ video.createTime }}</div>
                          <div class="video-button"></div>
                        </div>
                      </div>
                    </el-card>
                    <!-- </el-col> -->
                  </el-row>
                </div>
                <div class="panel-content"
                     v-show="info.showFlag"
                     v-if="info.contentType == '2' && $route.query.id">
                  <div class="activity-type-check"
                       v-show="info.activityListShow">
                    <div class="activity-type-item"
                         :class="{ active: radioInfo.value == info.activityTypeRadio }"
                         v-for="radioInfo in info.activityTypeList"
                         :key="radioInfo.id"
                         @click="handleRadioChange(radioInfo.value, info, i, item, index)">{{ radioInfo.name }}</div>
                  </div>
                  <div class="third-panel"
                       v-for="activityInfo in info.activityList"
                       :key="activityInfo.id">
                    <div class="panel-header">
                      <div class="title">
                        <img :src="activityTitleIcon"
                             alt="" /><span>{{ activityInfo.activityName }}</span>
                      </div>
                      <div class="button"></div>
                    </div>
                    <div class="panel-content">
                      <div class="content-info"
                           v-if="activityInfo.activityContent">
                        <!-- <div class="ql-container ql-snow">
                          <div class="ql-editor" v-html="activityInfo.activityContent"></div>
                        </div> -->
                        <div class="w-e-text-container"
                             @click="handleImgClick">
                          <div class="w-e-text"
                               v-html="activityInfo.activityContent"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-content"
                 v-show="item.showFlag"
                 v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 && item.contentType != '2'">
              <div class="content-panel"
                   v-if="item.contentType == '0'">
                <div class="content-info"
                     v-if="item.eduCourseThemeContent.length > 0">
                  <!-- <div class="ql-container ql-snow">
                    <div class="ql-editor" v-html="item.eduCourseThemeContent[0].content"></div>
                  </div> -->
                  <div class="w-e-text-container"
                       @click="handleImgClick">
                    <div class="w-e-text"
                         v-html="item.eduCourseThemeContent[0].content"></div>
                  </div>
                </div>
                <div class="file-info"
                     v-if="item.eduCourseThemeCatalogueContentFile && item.eduCourseThemeCatalogueContentFile.length > 0">
                  <div class="file-item"
                       v-for="(fileInfo, fileIndex) in item.eduCourseThemeCatalogueContentFile"
                       :key="fileInfo.id"
                       :style="{ 'margin-right': ((fileIndex+1) % fileNum == 0) ? '0' : '0.52083vw', width: '22.08333vw' }">
                    <div class="content">
                      <img class="icon"
                           :src="fileIconPDF"
                           alt=""
                           v-if="fileInfo.iconType == 'pdf'" />
                      <img class="icon"
                           :src="fileIconWord"
                           alt=""
                           v-if="fileInfo.iconType == 'doc'" />
                      <img class="icon"
                           :src="fileIconExcel"
                           alt=""
                           v-if="fileInfo.iconType == 'xls'" />
                      <img class="icon"
                           :src="fileIconPPT"
                           alt=""
                           v-if="fileInfo.iconType == 'ppt'" />
                      <div class="message">
                        <div class="title">{{ fileInfo.originalName }}</div>
                        <!-- <el-tooltip class="item" effect="light" popper-class="customPopper" :content="fileInfo.remark" placement="top-start">
                          <div class="ltitle">{{ fileInfo.remark }}</div>
                        </el-tooltip> -->
                      </div>
                    </div>
                    <div class="bottom">
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="附件下载"
                                  placement="bottom"
                                  v-if="fileInfo.isDownload == '1'">
                        <img :src="fileDownloadIcon"
                             alt=""
                             @click="fileView(fileInfo,'download')"
                             style="margin-right: 0.41667vw"
                             v-if="fileInfo.isDownload == '1'" />
                      </el-tooltip>
                      <el-divider direction="vertical"
                                  v-if="fileInfo.isDownload == '1'"></el-divider>
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="附件预览"
                                  placement="bottom">
                        <img :src="fileViewIcon"
                             alt=""
                             @click="fileView(fileInfo,'view')"
                             style="margin: 0 0.26042vw 0 0.41667vw" />
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-panel"
                   v-if="item.contentType == '1'">
                <el-row style="padding: 1.25vw 0.9375vw;">
                  <!--type="flex"-->
                  <!-- <el-col :span="videoSpan" v-for="(video, k) in item.eduCourseThemeCatalogueContentVideo" :key="video.id" :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '3.5vw' : '0', 'margin-bottom': '1.04167vw', }">'margin-right': (index + 1) % 4 != 0 ? '5.4%' : '0', -->
                  <el-card shadow="hover"
                           class="video-card"
                           v-for="(video, k) in item.eduCourseThemeCatalogueContentVideo"
                           :key="video.id"
                           :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '0.67708vw' : '0', 'margin-bottom': '0.67708vw', 'width': '22.08333vw', display: 'inline-block' }"
                           :body-style="{ padding: 'undefined' }"
                           @click.native="videoView(video)">
                    <div class="video-body"
                         :style="{ 'background-image': 'url(' + (video.fontImg ? video.fontImg : defaultImg) + ')', 'background-size': '100% 100%', }">
                      <img :src="videoPlayIcon"
                           alt="" />
                    </div>
                    <div class="info-body video-item">
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  :content="video.videoName"
                                  placement="top">
                        <div class="title">{{ video.videoName }}</div>
                      </el-tooltip>
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  :content="video.intro"
                                  placement="top"
                                  v-if="video.intro">
                        <div class="ltitle"
                             v-if="video.intro">{{ video.intro }}</div>
                      </el-tooltip>
                      <div class="bottom">
                        <div class="video-time">{{ video.createTime }}</div>
                        <div class="video-button"></div>
                      </div>
                    </div>
                  </el-card>
                  <!-- </el-col> -->
                </el-row>
              </div>
              <div class="content-panel"
                   v-if="item.contentType == '2' && $route.query.id">
                <div class="activity-type-check"
                     v-show="item.activityListShow">
                  <div class="activity-type-item"
                       :class="{ active: radioInfo.value == item.activityTypeRadio }"
                       v-for="radioInfo in item.activityTypeList"
                       :key="radioInfo.id"
                       @click="handleRadioChange(radioInfo.value, null, null, item, index)">{{ radioInfo.name }}</div>
                </div>
                <div class="third-panel"
                     v-for="activityInfo in item.activityList"
                     :key="activityInfo.id">
                  <div class="panel-header">
                    <div class="title">
                      <img :src="activityTitleIcon"
                           alt="" />
                      <span>{{ activityInfo.activityName }}</span>
                    </div>
                    <div class="button"></div>
                  </div>
                  <div class="panel-content">
                    <div class="content-info"
                         v-if="activityInfo.activityContent">
                      <!-- <div class="ql-container ql-snow">
                        <div class="ql-editor" v-html="activityInfo.activityContent"></div>
                      </div> -->
                      <div class="w-e-text-container"
                           @click="handleImgClick">
                        <div class="w-e-text"
                             v-html="activityInfo.activityContent"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="empty-content-panel"
             v-if="courseContentList.length == 0">
          <img class="empty-img"
               :src="emptyImg"
               alt="" />
          <div class="empty-title">暂无内容</div>
          <!-- <div class="empty-ltitle">需要先进行内容设置后才能进行内容操作</div> -->
        </div>
      </div>
    </div>
    <el-image style="width: 0; height: 0;"
              ref="viewImg"
              :src="viewUrl"
              :preview-src-list="viewUrlList"></el-image>
    <suggests-view-dialog :suggestsViewDialogVisible.sync="suggestsViewDialogVisible"
                          :courseContentCatalogTargetId="courseContentCatalogTargetId"
                          @closeDialog="suggestsDialogClose"></suggests-view-dialog>
    <practices-view-dialog :practicesViewDialogVisible.sync="practicesViewDialogVisible"
                           :courseContentCatalogTargetId="courseContentCatalogTargetId"
                           :courseContentCatalogTargetFlag="courseContentCatalogTargetFlag"
                           :courseContentCatalogTargetTypeValue="courseContentCatalogTargetTypeValue"
                           @closeDialog="practicesDialogClose"></practices-view-dialog>
    <video-view-dialog ref="videoView"
                       :videoViewDialogVisible.sync="videoViewDialogVisible"
                       :type="videoViewType"
                       :videoUrl="videoViewUrl"
                       @closeDialog="videoViewClose"></video-view-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import SuggestsViewDialog from './components/suggestsDialog.vue';
import PracticesViewDialog from './components/practicesDialog.vue';
import VideoViewDialog from '@/components/videoView/index.vue';
import emptyImg from '@/assets/img/empty-img.png';
import firstPanelTurnIcon from '@/assets/img/first-panel-turn-icon.png';
import fileViewIcon from '@/assets/img/file-view-icon.png';
import fileDownloadIcon from '@/assets/img/file-download-icon.png';
import secondPanelTurnIcon from '@/assets/img/second-panel-turn-icon.png';
import catalogSettingIcon from '@/assets/img/catalog-setting-icon.png';
import activityTitleIcon from '@/assets/img/activity-title-icon.png';
import fileIconPDF from '@/assets/img/file-icon-PDF.png';
import fileIconWord from '@/assets/img/file-icon-word.png';
import fileIconExcel from '@/assets/img/file-icon-excel.png';
import fileIconPPT from "@/assets/img/file-icon-ppt.png";
import videoPlayIcon from '@/assets/img/play-icon.png';
import defaultImg from '@/assets/img/default-img.png';
import defaultHeaderImg from '@/assets/img/default-header-img.png';
import defaultHeaderBg from '@/assets/img/detail-header-bg.png';
import { getToken } from '@/utils/auth';
import { getPermissionButton } from '@/utils/getUrlPermissionButtons';
import { getFileViewUrl, exportFile } from '@/utils/fileUtils.js';
export default {
  name: 'courseContentSetting',
  props: {
    courseContentCatalogActivityId: {
      type: Number,
      default: null,
    },
    activityData: {
      type: Object,
      default: null,
    },
  },
  components: { SuggestsViewDialog, PracticesViewDialog, VideoViewDialog, },
  data () {
    return {
      courseContentTargetInfo: [], // 目标清单list
      tableLoading: false, // 目标清单tableloading
      activeTab: 'courseIntroduce', // 介绍和内容切换
      courseContentInfo: {
        coverImg: '',
        courseThemeName: '',
        description: '',
        status: '',
        userName: '',
        createTime: '',
      }, // 课程基本信息
      emptyImg, // 空数据图片
      firstPanelTurnIcon, // 一级折叠icon
      fileViewIcon, // 文件预览icon
      fileDownloadIcon,//文件下载icon
      secondPanelTurnIcon, // 二级折叠icon
      catalogSettingIcon, // 课程内容的目录icon
      activityTitleIcon, // 活动头部icon
      fileIconPDF, // pdfIcon
      fileIconWord, // wordIcon
      fileIconExcel, // excelIcon
      fileIconPPT,//PPTIcon
      videoPlayIcon, // 视频icon
      defaultImg, // 默认图片
      defaultHeaderImg, // 头部信息默认图片
      defaultHeaderBg, // 头部背景图

      courseId: null, // 课程id
      courseContentId: null, // 编辑课程内容id
      courseContentList: [], // 课程内容详情
      contentTitle: '', // 选中目录的标题

      courseContentCatalogId: null, // 课程内容的目录id
      courseContentCatalogItemId: null, // 操作目录id
      parentCatalogId: null, // 父级目录id
      secondInfo: {}, // 二级对象
      secondIndex: null, // 二级对象下标
      firstInfo: {}, // 一级对象
      firstIndex: null, // 一级对象下标

      pageLoading: null, // 页面loading
      fileTypeList: ['png', 'jpg', 'jpeg'], // 文件类型list

      catalogData: [], // 目录结构

      bodyLeftHeight: '100%',
      bodyRightHeight: '100%',
      suggestsViewDialogVisible: false, // 目标建议查看显隐标识
      practicesViewDialogVisible: false, // 目标实践查看显隐标识
      courseContentCatalogTargetId: null, // 目标id
      courseContentCatalogTargetFlag: '', // 目标维度
      courseContentCatalogTargetTypeValue: '', // 目标维度字典值

      videoViewDialogVisible: false, // 视频预览标识
      videoViewType: '', // 视频类型
      videoViewUrl: '', // 视频预览url

      videoSpan: 6, // video宽度
      videoNum: 4, // video个数
      fileNum: 3, // 附件个数

      viewUrl: "",
      viewUrlList: [],
    };
  },
  watch: {
    courseContentCatalogActivityId (val) {
      if (val) {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.courseContentCatalogId = val;
        this.courseContentCatalogItemId = val;
        // this.contentTitle = isHaveContentCatalogData.catalogueName;
      }
    },
    activityData: {
      immediate: true,
      handler (val) {
        if (val) {
          this.courseContentInfo = {
            coverImg: val.activityImg || val.activityImageUrl,
            courseThemeName: val.courseName || val.couresTitle
          }
          this.contentTitle = val.activityName || val.activityTitle;
          this.getCourseContentCatalogContentInfo();
          // this.getCourseContentCatalogTargetList();
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState({
      typeOptions: (state) => state.common.courseTypeList,
      areaOptions: (state) => state.common.courseFieldList,
      stepOptions: (state) => state.common.ageScopeList,
      levelOptions: (state) => state.common.courseLevelList,
      activityTypeOptions: (state) => state.common.activityTypeList,
      menuList: (state) => state.common.menuList,
    }),
  },
  created () {
    this.courseContentId = this.$route.query.id ? Number(this.$route.query.id) : null;
    this.courseId = this.$route.query.courseId ? Number(this.$route.query.courseId) : null;
    let that = this;
    window.onresize = function () {
      that.resizeVideoInfo();
      that.resizeHeight();
    };
    that.resizeVideoInfo();
  },
  mounted () {
    if (this.$route.query.id) {
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getData();
      this.getCourseContentCatalogInfo();
    }
  },
  methods: {
    getToken,
    getPermissionButton,
    getFileViewUrl,
    exportFile,
    // 调整video个数和宽度
    resizeVideoInfo () {
      console.log(window.outerWidth);
      if (window.outerWidth <= 1800 && window.outerWidth > 1300) {
        this.videoSpan = this.$route.query.id ? 7 : 7;
        this.videoNum = this.$route.query.id ? 3 : 3;
        this.fileNum = this.$route.query.id ? 3 : 4;
      } else if (window.outerWidth <= 1300) {
        this.videoSpan = this.$route.query.id ? 10 : 10;
        this.videoNum = this.$route.query.id ? 2 : 2;
        this.fileNum = this.$route.query.id ? 3 : 4;
      } else if (window.outerWidth > 1800) {
        this.videoSpan = this.$route.query.id ? 5 : 5;
        this.videoNum = this.$route.query.id ? 4 : 4;
        this.fileNum = this.$route.query.id ? 3 : 4;
      }
    },
    // 调整height
    resizeHeight () {
      // console.log(this.$refs.bodyLeft.offsetHeight);
      console.log(this.$refs.bodyRight.offsetHeight);
      this.bodyLeftHeight = 'auto';
      this.bodyRightHeight = 'auto';
      this.$nextTick(() => {
        this.bodyLeftHeight = this.$refs.bodyRight.offsetHeight + 'px';
        this.bodyRightHeight = this.$refs.bodyRight.offsetHeight + 'px';
        if (this.$route.query.id &&
          this.$refs.bodyLeft.offsetHeight > this.$refs.bodyRight.offsetHeight
        ) {
          this.bodyLeftHeight = this.$refs.bodyLeft.offsetHeight + 'px';
          this.bodyRightHeight = this.$refs.bodyLeft.offsetHeight + 'px';
        }
      });
    },
    // 获取课程内容详情
    getData (callback) {
      this.$api.getCourseThemeList({ id: this.courseId, courseThemeId: this.courseContentId }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data && res.data.data.length > 0) {
            this.courseContentInfo = { ...res.data.data[0] };
          }
        } else {
          this.$message.error(res.data.msg);
        }
        callback && callback();
      });
    },
    // 获取课程内容目录list
    getCourseContentCatalogInfo () {
      this.$api.getCourseThemeCatalogTreeList({ courseThemeId: this.courseContentId }).then((res) => {
        if (res.data.code === 0) {
          res.data.data.map((item) => {
            item.isShowTree = true;
            if (item.children && item.children.length > 0) {
              item.children.map((info) => {
                info.isShowTree = true;
              });
            }
          });
          this.catalogData = res.data.data;
          this.$nextTick(() => {
            this.catalogData.map((itemData) => {
              this.isHaveCourseContentCatalogContent(itemData.id, (itemContentData) => {
                itemData.isHaveContent = itemContentData
              })
              if (itemData.children && itemData.children.length > 0) {
                itemData.isLeaf = false
                itemData.children.map((infoData) => {
                  this.isHaveCourseContentCatalogContent(infoData.id, (infoContentData) => {
                    infoData.isHaveContent = infoContentData
                  })
                  if (infoData.children && infoData.children.length > 0) {
                    itemData.isLeaf = false
                    infoData.children.map((resultData) => {
                      this.isHaveCourseContentCatalogContent(resultData.id, (resultContentData) => {
                        resultData.isHaveContent = resultContentData
                        resultData.isLeaf = true
                      })
                    })
                  } else {
                    infoData.isLeaf = true
                  }
                })
              } else {
                itemData.isLeaf = true
              }
            })
            console.log(this.catalogData)
            setTimeout(() => {
              let isHaveContentCatalogData = this.getHaveContentCatalog(this.catalogData)
              this.courseContentCatalogId = isHaveContentCatalogData.id;
              this.courseContentCatalogItemId = isHaveContentCatalogData.id;
              this.contentTitle = isHaveContentCatalogData.catalogueName;
              this.getCourseContentCatalogContentInfo();
              this.getCourseContentCatalogTargetList();
            }, 700)
          })
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.pageLoading.close()
      });
    },
    //获取有内容的目录信息
    getHaveContentCatalog (list) {
      let result = {}
      for (let i = 0; i < list.length; i++) {
        if (list[i].isHaveContent) {
          result = list[i]
          break;
        } else {
          if (list[i].children && list[i].children.length > 0) {
            this.getHaveContentCatalog(list[i].children)
          } else if (list[i].isLeaf) {
            result = list[i]
            break;
          }
        }
      }

      return result
    },
    // 判断课程内容目录是否有内容
    async isHaveCourseContentCatalogContent (id, callback) {
      let resultContentFlag = false
      let resultListFlag = false
      await this.$api.getCourseThemeDetailInfo({ courseThemeCatalogueId: id }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.length > 0) {
            resultContentFlag = true
          }
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
      });
      await this.$api.getCourseThemeTargetList({ courseThemeCatalogueId: id }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.length > 0) {
            resultListFlag = true
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
      if (resultContentFlag || resultListFlag) {
        callback && callback(true)
      } else {
        callback && callback(false)
      }
    },
    // 获取课程内容目录的内容list
    getCourseContentCatalogContentInfo () {
      this.$api.getCourseThemeDetailInfo({ courseThemeCatalogueId: this.courseContentCatalogId }).then((res) => {
        this.pageLoading.close();
        if (res.data.code === 0) {
          setTimeout(() => {
            if (res.data.data.length > 0) {
              res.data.data.map((item, index) => {
                item.showFlag = true;
                if (item.contentType == '2') {
                  console.log(this.activityTypeOptions);
                  // item.activityTypeRadio = this.activityTypeOptions[0].value;
                  let activityList = [];
                  this.activityTypeOptions.map((activityInfo) => {
                    if (item.eduActivity && item.eduActivity.length > 0) {
                      let index = item.eduActivity.findIndex((info) => {
                        return info.activityClassify == activityInfo.value;
                      });
                      let listIndex = activityList.findIndex((info) => {
                        return info.value == activityInfo.value;
                      });
                      if (index != -1 && listIndex == -1) {
                        activityList.push(activityInfo);
                      }
                    }
                  });
                  if (item.eduActivity && item.eduActivity.length > 0) {
                    item.activityListShow = true;
                  } else {
                    item.activityListShow = false;
                  }
                  if (activityList.length > 0) {
                    item.activityTypeRadio = activityList[0].value;
                    item.activityTypeList = activityList;
                    this.getCourseContentCatalogActivityList(item.id, activityList[0].value, null, null, item, index);
                  }
                }
                if (item.eduCourseThemeCatalogueContentFile && item.eduCourseThemeCatalogueContentFile.length > 0) {
                  item.eduCourseThemeCatalogueContentFile.map((fileInfo) => {
                    let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.'), fileInfo.fileUrl.length);
                    if (type.toLowerCase().indexOf('pdf') != -1) {
                      fileInfo.iconType = 'pdf';
                    }
                    if (type.toLowerCase().indexOf('doc') != -1) {
                      fileInfo.iconType = 'doc';
                    }
                    if (type.toLowerCase().indexOf('xls') != -1) {
                      fileInfo.iconType = 'xls';
                    }
                    if (type.toLowerCase().indexOf('ppt') != -1) {
                      fileInfo.iconType = 'ppt';
                    }
                  });
                }
                if (item.eduCourseThemeCatalogueContentTrees) {
                  item.eduCourseThemeCatalogueContentTrees.map((info, i) => {
                    info.showFlag = true;
                    if (info.contentType == '2') {
                      // info.activityTypeRadio = this.activityTypeOptions[0].value;
                      let activityList = [];
                      this.activityTypeOptions.map((activityInfo) => {
                        if (info.eduActivity && info.eduActivity.length > 0) {
                          let index = info.eduActivity.findIndex((result) => {
                            return result.activityClassify == activityInfo.value;
                          });
                          let listIndex = activityList.findIndex((result) => {
                            return result.value == activityInfo.value;
                          });
                          if (index != -1 && listIndex == -1) {
                            activityList.push(activityInfo);
                          }
                        }
                      });
                      if (info.eduActivity && info.eduActivity.length > 0) {
                        info.activityListShow = true;
                      } else {
                        info.activityListShow = false;
                      }
                      if (activityList.length > 0) {
                        info.activityTypeRadio = activityList[0].value;
                        info.activityTypeList = activityList;
                        this.getCourseContentCatalogActivityList(info.id, activityList[0].value, info, i, item, index);
                      }
                    }
                    if (info.eduCourseThemeCatalogueContentFile && info.eduCourseThemeCatalogueContentFile.length > 0) {
                      info.eduCourseThemeCatalogueContentFile.map((fileInfo) => {
                        let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.'), fileInfo.fileUrl.length);
                        if (type.toLowerCase().indexOf('pdf') != -1) {
                          fileInfo.iconType = 'pdf';
                        }
                        if (type.toLowerCase().indexOf('doc') != -1) {
                          fileInfo.iconType = 'doc';
                        }
                        if (type.toLowerCase().indexOf('xls') != -1) {
                          fileInfo.iconType = 'xls';
                        }
                        if (type.toLowerCase().indexOf('ppt') != -1) {
                          fileInfo.iconType = 'ppt';
                        }
                      });
                    }
                  });
                }
              });
              this.courseContentList = res.data.data;
              setTimeout(() => {
                this.resizeHeight();
              }, 400);
            } else {
              this.courseContentList = [];
              this.$nextTick(() => {
                this.resizeHeight();
              });
            }
          }, 200);
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.pageLoading.close();
      });
    },
    // 获取课程内容目录的活动内容list
    getCourseContentCatalogActivityList (id, type, secondItem, secondIndex, firstItem, firstIndex, callback) {
      this.$api.contentCatalogActivityHandle('/api/course/activity/list', { courseThemeCatalogueContentId: id, activityClassify: type, }, 'list').then((res) => {
        if (res.data.code === 0) {
          let info = {};
          if (secondItem) {
            info = { ...secondItem };
            info.activityList = res.data.data;
            this.$set(this.courseContentList[firstIndex].eduCourseThemeCatalogueContentTrees, secondIndex, info);
          } else {
            info = { ...firstItem };
            info.activityList = res.data.data;
            this.$set(this.courseContentList, firstIndex, info);
          }
          setTimeout(() => {
            this.resizeHeight();
            callback && callback();
          }, 300);
        } else {
          this.$message.error(res.data.msg);
          callback && callback();
        }
      });
    },
    // 获取目标清单信息list
    getCourseContentCatalogTargetList () {
      this.$api.getCourseThemeTargetList({ courseThemeCatalogueId: this.courseContentCatalogId }).then((res) => {
        if (res.data.code === 0) {
          this.courseContentTargetInfo = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 处理下拉turn事件
    handleTreeTurn (firstItem, firstIndex, secondItem, secondIndex) {
      let info = null;
      if (secondItem) {
        info = JSON.parse(JSON.stringify(secondItem));
        info.isShowTree = !info.isShowTree;
        this.$set(this.catalogData[firstIndex].children, secondIndex, info);
      } else {
        info = JSON.parse(JSON.stringify(firstItem));
        info.isShowTree = !info.isShowTree;
        if (info.isShowTree) {
          if (info.children && info.children.length > 0) {
            info.children.map((item) => {
              item.isShowTree = true
            })
          }
        } else {
          if (info.children && info.children.length > 0) {
            info.children.map((item) => {
              item.isShowTree = false
            })
          }
        }
        this.$set(this.catalogData, firstIndex, info);
      }
      // this.showMenuFlag = false
      // this.$nextTick(() => {
      //   console.log(this.courseThemeList)
      //   this.showMenuFlag = true
      // })
      // this.$forceUpdate();
    },
    // 切换目录
    handleTreeClick (info, firstItem, firstIndex, secondItem, secondIndex) {
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (info.isHaveContent || (!info.isHaveContent && info.isLeaf)) {
        this.courseContentCatalogId = info.id;
        this.courseContentCatalogItemId = info.id;
        this.contentTitle = info.catalogueName;
        this.getCourseContentCatalogContentInfo();
        this.getCourseContentCatalogTargetList();
      } else if (!info.isHaveContent && !info.isLeaf) {
        this.handleTreeTurn(firstItem, firstIndex, secondItem, secondIndex)
      }
    },
    // 内容操作
    handleContentItem (type, secondItem, secondIndex, firstItem, firstIndex) {
      this.secondInfo = secondItem;
      this.secondIndex = secondIndex;
      this.firstInfo = firstItem;
      this.firstIndex = firstIndex;
      switch (type) {
        case 'turnItem':
          let info = {};
          if (secondItem) {
            info = { ...secondItem };
            info.showFlag = !info.showFlag;
            this.$set(this.courseContentList[firstIndex].eduCourseThemeCatalogueContentTrees, secondIndex, info);
          } else {
            info = { ...firstItem };
            info.showFlag = !info.showFlag;
            this.$set(this.courseContentList, firstIndex, info);
          }
          this.$nextTick(() => {
            this.resizeHeight();
          });
          break;
      }
    },
    // 预览富文本图片
    handleImgClick (e) {
      console.log(e);
      if (e.target.nodeName == 'IMG') {
        this.viewUrl = e.target.currentSrc;
        this.viewUrlList = [e.target.currentSrc];
        this.$nextTick(() => {
          this.$refs.viewImg.showViewer = true;
        });
      }
    },
    // 预览文件
    fileView (fileInfo, type) {
      if (type == 'view') {
        let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.') + 1, fileInfo.fileUrl.length);
        if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
          this.viewUrl = fileInfo.fileUrl;
          this.viewUrlList = [fileInfo.fileUrl];
          this.$nextTick(() => {
            this.$refs.viewImg.showViewer = true;
          });
        } else {
          const { originalName, fileVersionId } = fileInfo
          this.getFileViewUrl('', { fileVersionId, name: originalName }, (data) => {
            if (data) {
              sessionStorage.setItem('viewUrl', data);
              let routeUrl = this.$router.resolve({ path: '/fileView.html' });
              window.open(routeUrl.href, '_blank');
            }
          });
        }
      } else {
        if (fileInfo.isDownload === '1') {
          let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.') + 1, fileInfo.fileUrl.length);
          if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
            this.$message.warning('该附件为图片，只可预览查看');
          } else {
            // window.location.href = fileInfo.fileUrl;
            this.exportFile(fileInfo.fileUrl, {}, fileInfo.originalName)
          }
        } else {
          this.$message.warning('该附件不支持下载！');
        }
      }
    },
    // 视频预览
    videoView (videoInfo) {
      this.videoViewDialogVisible = true;
      this.$nextTick(() => {
        let type = videoInfo.videoUrl.substr(videoInfo.videoUrl.lastIndexOf('.') + 1, videoInfo.videoUrl.length);
        console.log(type);
        this.videoViewType = type == 'mp4' || type == 'avi' ? 'video' : type == 'mp3' ? 'audio' : '';
        this.videoViewUrl = videoInfo.videoUrl;
      });
    },
    // 视频预览关闭
    videoViewClose () {
      this.videoViewDialogVisible = false;
      this.$nextTick(() => {
        this.videoViewType = '';
        this.videoViewUrl = '';
      });
    },
    // 目标清单操作
    handleTaggetItem (type, row) {
      if (type == 'suggestView') {
        this.suggestsViewDialogVisible = true;
        this.$nextTick(() => {
          this.courseContentCatalogTargetId = row.targetCatalogueId;
        });
      } else if (type == 'practiceView') {
        this.practicesViewDialogVisible = true;
        this.$nextTick(() => {
          this.courseContentCatalogTargetId = row.targetCatalogueId;
          this.courseContentCatalogTargetFlag = row.flag;
          this.courseContentCatalogTargetTypeValue = row.typeValue;
        });
      }
    },
    // 教学建议查看关闭
    suggestsDialogClose () {
      this.suggestsViewDialogVisible = false;
      this.$nextTick(() => {
        this.courseContentCatalogTargetId = null;
      });
    },
    // 教学实践查看关闭
    practicesDialogClose () {
      this.practicesViewDialogVisible = false;
      this.$nextTick(() => {
        this.courseContentCatalogTargetId = null;
        this.courseContentCatalogTargetFlag = '';
        this.courseContentCatalogTargetTypeValue = '';
      });
    },
    // 活动切换操作
    handleRadioChange (val, secondItem, secondIndex, firstItem, firstIndex) {
      let info = null;
      if (secondItem) {
        info = { ...secondItem };
        info.activityTypeRadio = val;
        this.$set(this.courseContentList[firstIndex].eduCourseThemeCatalogueContentTrees, secondIndex, info);
        this.getCourseContentCatalogActivityList(secondItem.id, val, info, secondIndex, firstItem, firstIndex);
      } else {
        info = { ...firstItem };
        info.activityTypeRadio = val;
        this.$set(this.courseContentList, firstIndex, info);
        this.getCourseContentCatalogActivityList(firstItem.id, val, secondItem, secondIndex, info, firstIndex);
      }
    },
  },
  beforeDestroy () {
    window.onresize = null;
  }
};
</script>
<style lang="scss" scoped>
.content-body {
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  min-height: 500px;
  .body-left {
    width: 288px;
    min-height: 500px;
    background-color: #ffffff;
    box-shadow: 0px 3px 17px rgba(0, 0, 0, 0.05);
    .el-menu-item:focus,
    .el-menu-item:hover {
      background-color: transparent !important;
    }
    .el-submenu .el-menu-item {
      height: auto;
      line-height: normal;
      padding: 0 !important;
      min-width: 0;
    }
    .tree-list {
      width: 100%;
      .tree-item {
        width: 100%;
      }
      .tree-title {
        width: calc(100% - 58px);
        padding: 20px 18px 20px 40px;
        font-size: 0.833333vw;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.75);
        cursor: pointer;
        position: relative;
        white-space: normal;
        word-break: break-all;
        line-height: 1.4;
        &.active {
          background-color: rgba(89, 126, 247, 0.05);
          border-left: 4px solid #597ef7;
          width: calc(100% - 62px);
          border-radius: 5px;
          &.parentShow {
            width: calc(100% - 106px);
          }
        }
        &.parentShow {
          width: calc(100% - 102px);
          padding: 20px 62px 20px 40px;
        }
        .parent-show {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          width: 52px;
          height: 28px;
          line-height: 28px;
          color: #ffffff;
          font-weight: 400;
          font-size: 0.729167vw;
          background-color: #597ef7;
          border-radius: 23px;
          text-align: center;
        }
        i {
          position: absolute;
          font-size: 1.041667vw;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
          // &.hideTurn{
          //   transform: rotate(-180deg) translateY(-50%);
          // }
        }
      }
      .sub-tree-list {
        width: 100%;
        .tree-title {
          width: calc(100% - 78px);
          padding: 20px 18px 20px 60px;
          &.active {
            width: calc(100% - 82px);
            &.parentShow {
              width: calc(100% - 126px);
            }
          }
          &.parentShow {
            width: calc(100% - 122px);
            padding: 20px 62px 20px 60px;
          }
        }
      }
    }
  }
  .body-right {
    width: calc(100% - 288px);
    .content-title {
      width: calc(100% - 24px);
      margin-left: 24px;
      padding: 28px 0 17px 0;
      font-size: 1.354167vw;
      font-weight: bold;
      color: #000000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 54px;
        height: 26px;
        margin: 0 28px;
        cursor: pointer;
      }
    }
  }
}
.poper-div {
  position: absolute;
  bottom: -50px;
  left: 20px;
  width: 240px;
  height: 50px;
  background-color: #ffffff;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  .popor-arrow {
    position: absolute;
    top: -10px;
    left: 40px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #ffffff;
  }
  .poper-item {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
    &:hover {
      color: #597ef7;
    }
  }
}
//tab样式
/deep/.el-tabs__nav-wrap {
  padding: 0 20px;
  height: 60px;
}

/deep/.el-tabs__nav {
  font-size: 20px;
  height: 60px;
  .el-tabs__item {
    height: 60px;
    line-height: 60px;
    font-size: 20px;
  }
}

/deep/.el-dialog__body {
  color: unset;
}
</style>
